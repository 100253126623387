<template>
  <div>
    <div class="banner">
      <img src="../../images/activityBanner.png" alt="" style="width: 1920px;height: 935px;">
    </div>
    <div style="padding-left: 153px;padding-top: 111px;background-color: rgb(239,239,239)">
      <div class="bottom">
        <el-card class="form">
          <p style="font-size: 20px">*为必填项</p>

            <el-space style="margin-top: 30px;" :size="15">
              *<el-input v-model="applyform.linkName" placeholder="联系人姓名" />
              *<el-input v-model="applyform.linkPhone" placeholder="联系人电话" />
            </el-space>
            <el-space style="margin-top: 22px;padding-left: 20px"  :size="38">
              <el-input v-model="applyform.linkWx" placeholder="联系人微信" style="width: 213.2px;" />
              <el-input v-model="applyform.linkEmail" placeholder="联系人邮箱" style="width: 213.2px;" />
            </el-space>

          <el-space>
            <div style="width:13px ;">*</div>
            <el-input v-model="applyform.companyName" placeholder="公司全称" style="margin-top: 22px;width: 464px"/>
          </el-space>
          <el-space>
            <div style="width:13px;"></div>
            <el-input v-model="applyform.companyAddress" placeholder="公司地址" style="margin-top: 22px;width: 464px;"/>
          </el-space>
            <el-space style="margin-top: 22px"  :size="15">
              *<el-input v-model="applyform.industry" placeholder="所属行业" style="width: 213.2px;"/>
              *<el-input v-model="applyform.budget" placeholder="共创预算" style="width: 213.2px;"/>
            </el-space>
          <div>
            <el-space>
              <div style="width:13px ;">*</div>
              <el-input
                v-model="applyform.demandDscp"
                :rows="6"
                type="textarea"
                placeholder="项目需求(例如：设计大赛/设计服务/创意征集等)"
                style="margin-top:22px;width: 464px;"
            />
            </el-space>
          </div>
          <button style="margin-left:33%;margin-top: 33px;width: 150px;height: 45px;border: #72767b;border-radius: 25px;font-size: 20px;font-weight: lighter" @click="submit">提交</button>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import {ElMessage, ElNotification} from "element-plus";

export default {
  name: "SignActivity",
  data(){
    return{
        applyform:{
          linkName:'',
          linkPhone:'',
          linkWx:'',
          linkEmail:'',
          companyName:'',
          companyAddress:'',
          industry:'',
          budget:'',
          demandDscp:''
        }
    }
  },
  methods:{
    submit(){
      if(this.applyform.linkName === '' || this.applyform.linkPhone === '' || this.applyform.companyName === '' || this.applyform.budget === '' || this.applyform.demandDscp === ''){
        ElMessage({
          message: '信息未填写完整',
          type: 'warning',
        })
        return;
      }
      this.$http({
        url:'/activity/apply/apply',
        method:'post',
        data:this.applyform
      }).then(({data}) =>{
          if (data.code === 200){
            ElNotification({
              title: '提交成功',
              message: '',
              type: 'success',
            })
            this.$router.push('/Home');
          }
      })
    }
  }
}
</script>

<style scoped>
.bottom{
  background:  url("../../images/activityPageInfo.png") no-repeat;
  height: 700px;
}
.form{
  height: 625px;
  width: 513px;
  margin-left: 900px;
  border-radius: 20px;
  padding-left: 20px;
  padding-top: 15px;
}
</style>
